
.resume__container{
    background:-moz-linear-gradient(90deg, rgba(2, 170, 176, 1) 0%, rgba(0, 205, 172, 1) 100%); 
    
    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(90deg, rgba(2, 170, 176, 1) 0%, rgba(0, 205, 172, 1) 100%);
    
    /* opera 11.10+ */
    background:-o-linear-gradient(90deg, rgba(2, 170, 176, 1) 0%, rgba(0, 205, 172, 1) 100%);
    
    /* ie 10+ */
    background:-ms-linear-gradient(90deg, rgba(2, 170, 176, 1) 0%, rgba(0, 205, 172, 1) 100%);
    
    /* global 94%+ browsers support */
    background:linear-gradient(90deg, rgba(2, 170, 176, 1) 0%, rgba(0, 205, 172, 1) 100%);
    
    background-clip:content-box;
    -webkit-background-clip:content-box;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    
}

.resume__container-header{
    text-align: center;
    font-size: 2rem;
    color: white;
}

.Resume-img{
    width: 640px;
    height: 828px;
    border: rgba(2, 170, 176, 1);
    border-radius: 100px;
    border-style: solid;
}

.Resume-img-hidden{
  width: 850px;
  height: 1px;

}

.resume-folder{
    margin: 0;
    padding-top: 50px;
    background:-moz-linear-gradient(90deg, rgba(2, 170, 176, 1) 0%, rgba(0, 205, 172, 1) 100%); 
    
  /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(90deg, rgba(2, 170, 176, 1) 0%, rgba(0, 205, 172, 1) 100%);
  
  /* opera 11.10+ */
    background:-o-linear-gradient(90deg, rgba(2, 170, 176, 1) 0%, rgba(0, 205, 172, 1) 100%);
  
  /* ie 10+ */
    background:-ms-linear-gradient(90deg, rgba(2, 170, 176, 1) 0%, rgba(0, 205, 172, 1) 100%);
  
  /* global 94%+ browsers support */
    background:linear-gradient(90deg, rgba(2, 170, 176, 1) 0%, rgba(0, 205, 172, 1) 100%);
  
    background-clip:content-box;
    -webkit-background-clip:content-box;
}

.resume__button{
    color: black;
    font-size: 25px;
    background-color: aqua;
    height: 100px;
    width: 250px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    border-color: aqua;
}


.resume__button {
    --c: #ffffff; /* the color */
    --b: .1em;    /* border length*/
    --d: 20px;    /* the cube depth */
    
    --_s: calc(var(--d) + var(--b));
    
    color: var(--c);
    border: solid #0000;
    border-width: var(--b) var(--b) var(--_s) var(--_s);
    background:
      conic-gradient(at left var(--d)  bottom var(--d),
        #0000 90deg, rgb(255 255 255 /0.3) 0 225deg,rgb(255 255 255 /0.6) 0) border-box,
      conic-gradient(at left var(--_s) bottom var(--_s),
        #0000 90deg,var(--c) 0) 0 100%/calc(100% - var(--b)) calc(100% - var(--b))  border-box;
    transform: translate(calc(var(--d)/-1),var(--d));
    clip-path: 
      polygon(
       var(--d) 0%, 
       var(--d) 0%, 
       100% 0%, 
       100% calc(100% - var(--d)),
       100% calc(100% - var(--d)),
       var(--d) calc(100% - var(--d))
      );
    transition: 0.5s;

    padding: 5px;
}
.resume__button:hover {
    transform: translate(0,0);
    clip-path: 
      polygon(
       0% var(--d), 
       var(--d) 0%, 
       100% 0%, 
       100% calc(100% - var(--d)), 
       calc(100% - var(--d)) 100%, 
       0% 100%
     );
}


.buttons__resume{
  text-align: center;
}

.resume-folder{
  text-align: center;

}