.home__container {
    text-align: left;
    margin-top: 15%;
    margin-left: 20%;
    font-size: 2rem;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}



h2{
    margin: 0;
    margin-top: 0%;
    margin-block-start: 0;
    margin-block-end: 0;
    
}
.gradient {
    /* ff 3.6+ */
    background:-moz-linear-gradient(90deg, rgba(2, 170, 176, 1) 0%, rgba(0, 205, 172, 1) 100%); 
    
    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(90deg, rgba(2, 170, 176, 1) 0%, rgba(0, 205, 172, 1) 100%);
    
    /* opera 11.10+ */
    background:-o-linear-gradient(90deg, rgba(2, 170, 176, 1) 0%, rgba(0, 205, 172, 1) 100%);
    
    /* ie 10+ */
    background:-ms-linear-gradient(90deg, rgba(2, 170, 176, 1) 0%, rgba(0, 205, 172, 1) 100%);
    
    /* global 94%+ browsers support */
    background:linear-gradient(90deg, rgba(2, 170, 176, 1) 0%, rgba(0, 205, 172, 1) 100%);
    
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}


.home__container-navbar {
    text-transform: uppercase;
    position: relative;
    display: flex;
    align-items: center;
    gap: 20px;

}

.options {
    --c: #00C9AC; /* the color */
    --b: .1em;    /* border length*/
    --d: 20px;    /* the cube depth */
    
    --_s: calc(var(--d) + var(--b));
    
    color: var(--c);
    border: solid #0000;
    border-width: var(--b) var(--b) var(--_s) var(--_s);
    background:
      conic-gradient(at left var(--d)  bottom var(--d),
        #0000 90deg, rgb(255 255 255 /0.3) 0 225deg,rgb(255 255 255 /0.6) 0) border-box,
      conic-gradient(at left var(--_s) bottom var(--_s),
        #0000 90deg,var(--c) 0) 0 100%/calc(100% - var(--b)) calc(100% - var(--b))  border-box;
    transform: translate(calc(var(--d)/-1),var(--d));
    clip-path: 
      polygon(
       var(--d) 0%, 
       var(--d) 0%, 
       100% 0%, 
       100% calc(100% - var(--d)),
       100% calc(100% - var(--d)),
       var(--d) calc(100% - var(--d))
      );
    transition: 0.5s;

    padding: 5px;
  }
  .options:hover {
    transform: translate(0,0);
    clip-path: 
      polygon(
       0% var(--d), 
       var(--d) 0%, 
       100% 0%, 
       100% calc(100% - var(--d)), 
       calc(100% - var(--d)) 100%, 
       0% 100%
     );
  }

.home__container-waste{
  margin-bottom: 450px;
}


