.projects__container{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    
}

.projects__container-header{
    text-align: center;
    font-size: 2rem;
}

.picture1{
    height:150px;
    width: 300px;
    object-fit:fill;
    border-radius: 5px;
}

.projects__container-information{
    display:flex;
    flex-direction:column;
    object-fit:fill;
    text-align: center;
    background: linear-gradient(90deg, rgba(2, 170, 176, 1) 0%, rgba(0, 205, 172, 1) 100%);
    border-radius: 25px;
    
}

.projects__container-card{
    display:flex;
    flex-direction:column;
    text-align: center;
    font-size: 1.4rem;
    font-family: Arial, Helvetica, sans-serif;
    justify-content: space-between;
}

.projects__container-holder{
    display: flex;
    flex-direction: row;
    inline-size: 35%;
    justify-content:center;
    column-gap: 40px;
    margin-right: 20%;
    position: relative;
    margin-left: 32%;
}



.projects__container-card-link{
    background:-moz-linear-gradient(90deg, rgba(2, 170, 176, 1) 0%, rgba(0, 205, 172, 1) 100%); 
    
    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(90deg, rgba(2, 170, 176, 1) 0%, rgba(0, 205, 172, 1) 100%);
    
    /* opera 11.10+ */
    background:-o-linear-gradient(90deg, rgba(2, 170, 176, 1) 0%, rgba(0, 205, 172, 1) 100%);
    
    /* ie 10+ */
    background:-ms-linear-gradient(90deg, rgba(2, 170, 176, 1) 0%, rgba(0, 205, 172, 1) 100%);
    
    /* global 94%+ browsers support */
    background:linear-gradient(90deg, rgba(2, 170, 176, 1) 0%, rgba(0, 205, 172, 1) 100%);
    
    background-clip:content-box;
    -webkit-background-clip:content-box;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

    border-radius: 25px;
}

.projects__container-card-picture{
    display: flex;
    object-fit:fill;
    justify-content: center;
}

.project__container-waste{
    margin-bottom: 800px;
}